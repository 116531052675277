import React from "react"
import {
  getCourseNavigation,
  useCourseStore,
} from "../../../../../store/courses"
import LayoutCourse from "../../../../../layouts/course"

import Paragraph from "../../../../../components/paragraph"
import CourseFooterNext from "../../../../../components/course-footer-next"
import HandDrawing from "../../../../../components/hand-drawing"
import Heading from "../../../../../components/heading"
import Poem from "../../../../../components/poem"
import Seo from "../../../../../components/seo"
import Stack from "../../../../../components/stack"
import NotationSingle from "../../../../../components/notation-single"
import PaperStack from "../../../../../components/paper-stack"

const Notation = ({ children }) => {
  return <NotationSingle type="circle">{children}</NotationSingle>
}

const Page = () => {
  const { getAnswer } = useCourseStore()
  const navigation = getCourseNavigation({ courseId: "reiseerinnerung" })

  const taskProps = {
    courseId: "reiseerinnerung",
    chapterId: "01-suedfrankreich",
    taskId: "bewegungen",
  }

  const answer = getAnswer({
    ...taskProps,
  })

  return (
    <LayoutCourse
      navigation={navigation}
      footer={
        <CourseFooterNext to="/kurse/reiseerinnerung/01-suedfrankreich/foto-zum-gedicht" />
      }
    >
      <Seo title="Bewegungen aufzeichnen" />
      <Stack>
        <Heading as="h2" level={2}>
          Bewegungen aufzeichnen
        </Heading>
        <HandDrawing saveData={answer} disabled ratio={0.7} />
        <Paragraph>
          Wow! Picasso hätte das auch nicht besser hinbekommen!
        </Paragraph>
        <Paragraph>
          Folgende Bewegungssignale sind in den ersten beiden Strophen des
          Gedichts versteckt:
        </Paragraph>
        <PaperStack>
          <Poem>
            <p>
              Der <Notation>Nordost</Notation> wehet,{" "}
            </p>
            <p>Der liebste unter den Winden </p>
            <p>Mir, weil er feurigen Geist </p>
            <p>Und gute Fahrt verheißet den Schiffern. </p>
            <p>
              <Notation>Geh</Notation> aber nun und grüße{" "}
            </p>
            <p>Die schöne Garonne, </p>
            <p>Und die Gärten von Bourdeaux </p>
            <p>
              <Notation>Dort</Notation>, wo am{" "}
              <Notation>scharfen Ufer</Notation>
            </p>
            <p>
              <Notation>Hingehet</Notation> der Steg und in den Strom
            </p>
            <p>
              <Notation>Tief fällt</Notation> der Bach,{" "}
              <Notation>darüber aber</Notation>
            </p>
            <p>Hinschauet ein edel Paar</p>
            <p>Von Eichen und Silberpappeln</p>
            <br />
            <p>Noch denket das mir wohl und wie</p>
            <p>
              Die breiten <Notation>Gipfel neiget</Notation>
            </p>
            <p>
              Der Ulmwald , <Notation>über</Notation> die Mühl',
            </p>
            <p>
              <Notation>Im Hofe</Notation> aber <Notation>wächset</Notation> ein
              Feigenbaum.
            </p>
            <p>
              An Feiertagen <Notation>gehn</Notation>
            </p>
            <p>Die braunen Frauen daselbst</p>
            <p>
              <Notation>Auf seidnen Boden</Notation>,
            </p>
            <p>Zur Märzenzeit,</p>
            <p>Wenn gleich ist Nacht und Tag,</p>
            <p>
              Und <Notation>über langsamen Stegen</Notation>,
            </p>
            <p>Von goldenen Träumen schwer.</p>
            <p>
              Einwiegende <Notation>Lüfte ziehen</Notation>.
            </p>
          </Poem>
        </PaperStack>
      </Stack>
    </LayoutCourse>
  )
}

export default Page
